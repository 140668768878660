import { call, fork, put, takeEvery } from 'redux-saga/effects'

import {
	GET_FROM_STORAGE,
	REMOVE_TO_STORAGE,
	SET_TO_STORAGE,
	getFromStorageFailure,
	getFromStorageSuccess,
	removeToStorageFailure,
	removeToStorageSuccess,
	setToStorageFailure,
	setToStorageSuccess,
} from 'shared/actions/storageActions'

import { getFromStorageService, removeToStorageService, setToStorageService } from 'shared/services/storageServices'

export function* watchSetToStorage() {
	yield takeEvery(SET_TO_STORAGE, setToStorageSaga)
}

export function* setToStorageSaga({ payload }) {
	const { transactionId } = payload
	try {
		const { requestKey, requestBody } = payload

		yield call(setToStorageService, requestKey, requestBody)

		yield put(setToStorageSuccess(transactionId))
	} catch (err) {
		yield put(setToStorageFailure(err, transactionId))
	}
}

export function* watchGetFromStorage() {
	yield takeEvery(GET_FROM_STORAGE, getFromStorageSaga)
}

export function* getFromStorageSaga({ payload }) {
	const { transactionId } = payload
	try {
		const { requestKey } = payload

		const body = yield call(getFromStorageService, requestKey)

		yield put(getFromStorageSuccess(body, transactionId))
	} catch (err) {
		yield put(getFromStorageFailure(err, transactionId))
	}
}

export function* watchRemoveToStorage() {
	yield takeEvery(REMOVE_TO_STORAGE, removeToStorageSaga)
}

export function* removeToStorageSaga({ payload }) {
	const { transactionId } = payload
	try {
		const { resourceKey } = payload

		yield call(removeToStorageService, resourceKey)

		yield put(removeToStorageSuccess(transactionId))
	} catch (err) {
		yield put(removeToStorageFailure(err, transactionId))
	}
}

export default function* storageSaga() {
	yield fork(watchSetToStorage)
	yield fork(watchGetFromStorage)
	yield fork(watchRemoveToStorage)
}

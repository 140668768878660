import { Layout } from 'antd'
import styled from '@xstyled/styled-components'

export const Root = styled(Layout.Sider)`
	height: 100%;
	background-color: backgroundSecondary;
	a {
		box-shadow: unset !important;
	}
`

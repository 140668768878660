import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { IMPORT_FILE, importFileFailure, importFileSuccess } from 'shared/actions/fsActions'

import { importFileService } from 'shared/services/fsServices'

export function* watchImportFile() {
	yield takeEvery(IMPORT_FILE, importFileSaga)
}

export function* importFileSaga({ payload }) {
	const { transactionId } = payload
	try {
		const { filePath } = payload
		const fileContent = yield call(importFileService, filePath)
		yield put(importFileSuccess(fileContent, transactionId))
	} catch (err) {
		yield put(importFileFailure(err, transactionId))
	}
}

export default function* fsSaga() {
	yield fork(watchImportFile)
}

import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/layout/__styles__/Layout.styles'

const Layout = ({ children, ...otherProps }) => {
	return <Styled.Root {...otherProps}>{children}</Styled.Root>
}

export default Layout

Layout.propTypes = {
	/** The content to the section HTML element. */
	children: PropTypes.node,
}

import { Breadcrumb as _Breadcrumb } from 'antd'
import styled from '@xstyled/styled-components'

export const Root = styled.div`
	width: 100%;
	background-color: white;
	padding: 16px 48px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`

export const BreadcrumbContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
`

export const Title = styled.span`
	${({ theme }) => ({ ...theme.fonts.title })};
`

export const Breadcrumb = styled(_Breadcrumb)`
	${({ theme }) => ({ ...theme.fonts.medium })};
`

import { Link } from 'react-router-dom'
import styled from '@xstyled/styled-components'

export const RootLink = styled(Link)`
	position: relative;
	box-shadow: unset;
`

export const Logo = styled.img`
	width: 200px;
`

const theme = {
	colors: {
		backgroundPrimary: '#E6E5E6',
		backgroundSecondary: '#ffffff',
		warning: '#A41421',
		blue1: 'rgba(0, 132, 255, 0.06)',
		primary1: '#FDEFF1',
		primary2: '#FBDBDE',
		primary3: '#F18992',
		primary4: '#E73747',
		primary5: '#D2192A',
		primary6: '#A41421',
		primary7: '#8E111C',
		primary8: '#770E18',
		primary9: '#600C13',
		primary10: '#49090F',
		caption: '#8C8C8C',
	},
	borders: {
		regular: '1px solid #d9d9d9',
		bold: '2px solid #A41421',
	},
	fonts: {
		title: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: 20,
			lineHeight: '28px',
		},
		textTitle: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: 16,
			lineHeight: '24px',
		},
		semiBold: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 16,
			lineHeight: '24px',
		},
		bold: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: 14,
			lineHeight: '22px',
		},
		medium: {
			fontFamily: 'Marianne',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			lineHeight: '22px',
		},
	},
}

module.exports = theme

import { useTranslation } from 'react-i18next'
import React from 'react'

import Page from 'shared/components/page/Page'

const NoMatchPage = () => {
	const { t } = useTranslation('root')

	return (
		<Page id="no-match" style={{ backgroundColor: '#cce7ff' }}>
			<h2>{t('root:no-match.title')}</h2>
			<p>{t('root:no-match.message')}</p>
		</Page>
	)
}

export default NoMatchPage

import { useTranslation } from 'react-i18next'
import PropTypes from '@ljn/prop-types'
import React, { Fragment } from 'react'

import * as Styled from 'shared/components/logo/__styles__/Logo.styles'

const Logo = ({ link, ...othersProps }) => {
	const { t } = useTranslation('root')

	const Root = !!link ? Styled.RootLink : Fragment

	return (
		<Root {...(!!link ? { to: link } : {})}>
			<Styled.Logo {...othersProps} src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt={t('root:header.logo-alt')} />
		</Root>
	)
}

Logo.propTypes = {
	/** Used to add a link to the image.
	 * Also used to determine if the container of the image must be a link or a simple div.
	 * If link is define this component must be wrapped with <Rooter>. */
	link: PropTypes.bool,
}

Logo.defaultProps = {
	link: null,
}

export default Logo

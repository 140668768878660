import { Breadcrumb, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'

import { selectProject } from 'modules/project/selectors/projectSelectors'

import * as Styled from 'shared/components/layout/__styles__/SubHeader.styles'

const SubHeader = () => {
	const { t } = useTranslation()

	const { pathname } = useLocation()

	const project = useSelector(selectProject)

	const location = useMemo(() => pathname.split('/')[1], [pathname])
	const displayProjectTitle = useMemo(() => !!pathname.split('/')[2], [pathname])
	const isOnProjectsPage = useMemo(() => location === '', [location])

	return (
		<Styled.Root>
			{['', 'projects', 'project', 'users'].includes(location) && (
				<>
					<Styled.BreadcrumbContainer>
						<Styled.Breadcrumb>
							<Breadcrumb.Item>{t('root:subHeader.home')}</Breadcrumb.Item>
							<Breadcrumb.Item>{t(`root:subHeader.${location || 'projects'}.breadcrumb`)}</Breadcrumb.Item>
						</Styled.Breadcrumb>
						<Styled.Title level={3}>
							{displayProjectTitle ? project?.title : t(`root:subHeader.${location || 'projects'}.title`)}
						</Styled.Title>
					</Styled.BreadcrumbContainer>
					{isOnProjectsPage && (
						<Button
							icon={<DownloadOutlined />}
							href={`${window._env_.REACT_APP_BASE_URL}${window._env_.REACT_APP_API_ROUTE}/projects/csvmodel`}
						>
							{t('root:subHeader.csvDownload')}
						</Button>
					)}
				</>
			)}
		</Styled.Root>
	)
}

export default SubHeader

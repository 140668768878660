import { Button, Dropdown } from 'antd'
import styled from '@xstyled/styled-components'

import _Content from 'shared/components/layout/Content'
import _Layout from 'shared/components/layout/Layout'

export const Layout = styled(_Layout)`
	flex: 1;
`

export const Content = styled(_Content)`
	display: flex;
	flex-direction: column;
`

export const Container = styled.div`
	margin: 24px;
	background-color: backgroundSecondary;
	flex: 1;
	overflow: hidden;
`

export const LeftContainer = styled.div`
	height: 100%;
`

export const RightContainer = styled.div`
	height: 100%;
`

export const OpenSiderButton = styled(Button)`
	margin-right: 20px;
`

export const UserDropDown = styled(Dropdown)`
	height: 100%;
	border-bottom: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`

export const UserContainer = styled.div`
	height: 100%;
`

export const UserSpan = styled.div`
	margin-right: 4px;
	margin-left: 8px;
`

import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/page/__styles__/Page.styles'

const Page = ({ id, children, ...props }) => {
	return (
		<Styled.Root data-testid="page" key={id} {...props}>
			{children}
		</Styled.Root>
	)
}

Page.propTypes = {
	children: PropTypes.node,
	id: PropTypes.string.isRequired,
}

export default Page

import { Layout } from 'antd'
import styled from '@xstyled/styled-components'

export const Root = styled(Layout.Header)`
	height: unset;
	background-color: white;
	border-bottom: regular;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

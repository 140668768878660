export const STARTUP = 'root/startup'
export const STARTUP_SUCCESS = 'root/startupSuccess'
export const STARTUP_FAILURE = 'root/startupFailure'

export const startup = () => {
	return {
		type: STARTUP,
	}
}

export const startupSuccess = () => {
	return {
		type: STARTUP_SUCCESS,
	}
}

export const startupFailure = (error) => {
	return {
		type: STARTUP_FAILURE,
		payload: {
			error: error,
			errorString: error?.toString(),
		},
	}
}

import PropTypes from '@ljn/prop-types'
import React from 'react'

import * as Styled from 'shared/components/layout/__styles__/Sider.styles'

const Sider = ({ collapsed, children, ...otherProps }) => {
	return (
		<Styled.Root breakpoint="lg" {...otherProps} collapsible trigger={null} collapsed={collapsed}>
			{children}
		</Styled.Root>
	)
}

export default Sider

Sider.propTypes = {
	/** To set the current state of the sidebar, whether it is collapsed or not. */
	collapsed: PropTypes.bool,
	/** The content to the aside HTML element. */
	children: PropTypes.node,
}

Sider.defaultProps = {
	collapsed: false,
}

import { Route, Router, Switch } from 'react-router-dom'
import { Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { lazy, useEffect } from 'react'
import getModuleWithConfig from '@ljn/auth-front'
import i18n from 'i18next'

import { selectIsAppReady } from 'modules/root/selectors/rootSelectors'

import { getHistory } from 'shared/utils/historyUtils'

import { syncWithLocation } from 'shared/actions/locationActions'

import ErrorBoundary from 'shared/components/error/ErrorBoundary'
import Loader from 'shared/components/loader/Loader'
import Main from 'shared/components/app/Main'
import NoMatchPage from 'shared/components/page/NoMatchPage'

import * as Styled from 'shared/components/app/__styles__/App.styles'

const Users = lazy(() => import(/* webpackPrefetch: true */ 'modules/users'))
const Projects = lazy(() => import(/* webpackPrefetch: true */ 'modules/projects'))
const Project = lazy(() => import(/* webpackPrefetch: true */ 'modules/project'))

const history = getHistory()

const {
	AuthModule,
	config: authConfig,
	forms: { changePassword: ChangePasswordForm },
} = getModuleWithConfig({
	api: {
		route: `${window._env_.REACT_APP_BASE_URL}${window._env_.REACT_APP_API_ROUTE}`,
		children: {
			auth: {
				route: 'auth',
				children: {
					me: `${window._env_.REACT_APP_API_ROUTE}/profiles/me`,
				},
			},
			users: {
				route: 'users',
				children: {
					changePassword: '{{id}}/password',
				},
			},
		},
	},
	frontendRoutes: {
		activateAccount: '/auth/initial-login',
		signIn: '/login',
		forgottenPassword: '/forgottenPassword',
		resetPassword: '/resetPassword',
	},
	pageWrapper: function pageWrapper(config, children) {
		return (
			<Styled.Page>
				<Styled.Content role="main">
					<Styled.Logo />
					{children}
				</Styled.Content>
			</Styled.Page>
		)
	},
	notificationWrapper: function notificationWrapper(config, notification) {
		return (
			<Styled.Notification key={notification.key} role="alert" variant={`alert ${notification.messageType}`} closable>
				{notification.message}
			</Styled.Notification>
		)
	},
	/* The sentence structure in this renderer is hardcoded because the project
	 * embeds an outdated version of react-i18next without the appropriate Trans
	 * component and the deadline is too close to risk introducing regressions by
	 * updating it. */
	privateSubscriptionNotice: function privateSubscriptionNotice(config) {
		return (
			<Styled.Notice variant="highlight" size="sm">
				{i18n.t('root:auth.getAccountIntro')}
				<a href={`mailto:${i18n.t('root:auth.getAccountEmail')}`}>{i18n.t('root:auth.getAccountEmail')}</a>.
			</Styled.Notice>
		)
	},
	bruteforceNotice: function bruteforceNotice(config, message) {
		return (
			<Styled.Notice variant="highlight" size="sm">
				{message}
			</Styled.Notice>
		)
	},
	formWrapper: function formWrapper(config, children, isSubmitting, formErrors) {
		return (
			<Spin spinning={isSubmitting} size="large">
				{formErrors && <Styled.Notice variant="alert error">{formErrors}</Styled.Notice>}
				{children}
			</Spin>
		)
	},
	publicEmails: false,
	publicSubscription: false,
	emailValidation: false,
	persistencePolicy: {
		enabled: true,
		defaultChecked: true,
		duration: -1,
	},
	t: i18n.getFixedT(null, ['auth']),
})

let authBundlesLoaded = false
const _initAuthI18n = () => {
	if (!authBundlesLoaded) {
		for (const [locale, resources] of Object.entries(authConfig.i18n)) {
			i18n.addResourceBundle(locale, 'auth', resources)
			authBundlesLoaded = true
		}
	}
}

const App = () => {
	_initAuthI18n()

	const isAppReady = useSelector(selectIsAppReady)

	const dispatch = useDispatch()

	useEffect(() => history.listen((location) => dispatch(syncWithLocation(location))), [dispatch])

	return (
		<ErrorBoundary>
			{isAppReady ? (
				<Router history={history}>
					<AuthModule>
						<Main path={'/'}>
							<Switch>
								{/*TEMP*/}
								<Route exact path="/">
									<Projects />
								</Route>
								<Route path="/project/:projectId?">
									<Project />
								</Route>
								<Route exact path="/users">
									<Users />
								</Route>
								<Route exact path="/changePassword">
									<Styled.PasswordPage>
										<ChangePasswordForm />
									</Styled.PasswordPage>
								</Route>
								{/*TEMP*/}
								<Route path="*">
									<NoMatchPage />
								</Route>
							</Switch>
						</Main>
					</AuthModule>
				</Router>
			) : (
				<Loader />
			)}
		</ErrorBoundary>
	)
}

export default App

import { DownOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { logout, selectCurrentUser } from '@ljn/auth-front'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import PropTypes from '@ljn/prop-types'
import React, { useCallback, useState } from 'react'

import { navigate } from 'shared/utils/historyUtils'

import Header from 'shared/components/layout/Header'
import Logo from 'shared/components/logo/Logo'
import Sider from 'shared/components/layout/Sider'
import SubHeader from 'shared/components/layout/SubHeader'

import * as Styled from 'shared/components/app/__styles__/Main.styles'

const Main = ({ children }) => {
	const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(false)

	const currentUser = useSelector(selectCurrentUser)

	const dispath = useDispatch()

	const { t } = useTranslation('root')

	const { pathname } = useLocation()

	const _onOpenSidebarButtonClick = () => {
		setIsSideBarCollapsed((prev) => !prev)
	}

	const _onChangePassword = useCallback(() => navigate('/changePassword'), [])
	const _onLogout = useCallback(() => dispath(logout()), [dispath])

	return (
		<>
			<Header>
				<Styled.LeftContainer>
					<Styled.OpenSiderButton shape="circle" icon={<MenuOutlined />} onClick={_onOpenSidebarButtonClick} />
					<Logo link={'/'} />
				</Styled.LeftContainer>
				{!!currentUser && (
					<Styled.RightContainer>
						<Styled.UserDropDown
							overlay={
								<Menu>
									<Menu.Item key="changePassword" onClick={_onChangePassword}>
										{t('root:header.changePassword')}
									</Menu.Item>
									<Menu.Item key="logout" onClick={_onLogout}>
										{t('root:header.logout')}
									</Menu.Item>
								</Menu>
							}
						>
							<Styled.UserContainer>
								<UserOutlined />
								<Styled.UserSpan>{currentUser.firstName}</Styled.UserSpan>
								<DownOutlined />
							</Styled.UserContainer>
						</Styled.UserDropDown>
					</Styled.RightContainer>
				)}
			</Header>
			<Styled.Layout>
				<Sider collapsed={isSideBarCollapsed}>
					<Menu mode="inline" tabIndex={0} selectedKeys={[`menu_item_${pathname === '/users' ? 1 : 0}`]}>
						<Menu.Item key="menu_item_0">
							<Link to="/">{t('root:sider.projects')} </Link>
						</Menu.Item>
						<Menu.Item key="menu_item_1">
							<Link to="/users">{t('root:sider.users')} </Link>
						</Menu.Item>
					</Menu>
				</Sider>
				<Styled.Content role="main">
					<SubHeader />
					<Styled.Container>{children}</Styled.Container>
				</Styled.Content>
			</Styled.Layout>
		</>
	)
}

export default Main

Main.propTypes = {
	/** Define the content to the main HTML element. */
	children: PropTypes.node,
}

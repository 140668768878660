import produce from 'immer'

import { STARTUP_FAILURE, STARTUP_SUCCESS } from 'modules/root/actions/rootActions'

export const initialState = { isAppReady: false }

export default function rootReducer(state, action) {
	return produce(state || initialState, (draft) => {
		switch (action.type) {
			case STARTUP_FAILURE:
			case STARTUP_SUCCESS: {
				draft.isAppReady = true
				break
			}
			default: {
				return state
			}
		}
	})
}

export const IMPORT_FILE = 'fs/importFile'
export const IMPORT_FILE_SUCCESS = 'fs/importFileSuccess'
export const IMPORT_FILE_FAILURE = 'fs/importFileFailure'

export const importFile = (filePath, transactionId = null) => ({
	type: IMPORT_FILE,
	payload: {
		filePath,
		transactionId,
	},
})

export const importFileSuccess = (responseBody, transactionId = null) => ({
	type: IMPORT_FILE_SUCCESS,
	payload: {
		responseBody,
		transactionId,
	},
})

export const importFileFailure = (err, transactionId = null) => ({
	type: IMPORT_FILE_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
		transactionId,
	},
})

export const SYNC_WITH_LOCATION = 'location/syncWithLocation'
export const SYNC_WITH_LOCATION_SUCCESS = 'location/syncWithLocationSuccess'
export const SYNC_WITH_LOCATION_FAILURE = 'location/syncWithLocationFailure'

export const syncWithLocation = (location) => ({
	type: SYNC_WITH_LOCATION,
	payload: {
		location,
	},
})

export const syncWithLocationSuccess = () => ({
	type: SYNC_WITH_LOCATION_SUCCESS,
})

export const syncWithLocationFailure = (err) => ({
	type: SYNC_WITH_LOCATION_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
	},
})

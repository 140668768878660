import React from 'react'
import ReactDOM from 'react-dom'

import 'antd/dist/antd.css'
import 'index.css'

import Root from 'Root'

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK === 'true') {
	require('./mocks').mockServer({ environment: process.env.NODE_ENV })
}

ReactDOM.render(
	<React.StrictMode>
		<Root />
	</React.StrictMode>,
	document.getElementById('root')
)

import axios from 'axios'

export const requestService = async (url, options = null) => {
	try {
		options = options || {
			method: 'GET',
			query: null,
			body: null,
			headers: {},
			withCredentials: true,
		}

		const headers = {
			...options.headers,
		}

		const response = await axios(url, {
			headers,
			method: options.method,
			data: options.body,
			params: options.query,
			withCredentials: true,
			...options,
		})

		if (response.status >= 200 && response.status < 300) {
			return {
				body: response.data,
				status: response.status,
				headers: response.headers,
			}
		} else {
			throw new Error(response.statusText)
		}
	} catch (err) {
		throw err
	}
}

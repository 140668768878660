import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { createStore } from 'redux-dynamic-modules'
import { getSagaExtension } from 'redux-dynamic-modules-saga'
import { initReactI18next } from 'react-i18next'
import { i18n as reclameI18n } from '@ljn/reclame'
import React, { Suspense } from 'react'

import i18n from 'i18next'

import App from 'shared/components/app/App'
import Loader from 'shared/components/loader/Loader'

import theme from '__theme__'

import rootModule from 'modules/root'

i18n.use(initReactI18next).init({
	lng: process.env.REACT_APP_DEFAULT_LANGUAGE,
	fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
	interpolation: {
		escapeValue: false,
	},
	react: {
		bindI18nStore: 'added',
	},
})

i18n.on('languageChanged', (lng) => reclameI18n.changeLanguage(lng))
if (process.env.REACT_APP_DEFAULT_LANGUAGE) {
	reclameI18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE)
}

const store = createStore(
	{
		extensions: [getSagaExtension()],
	},
	rootModule
)

const Root = () => (
	<Provider store={store}>
		<Suspense fallback={<Loader />}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</Suspense>
	</Provider>
)

export default Root
